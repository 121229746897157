import { Route, Routes, useNavigate } from "react-router-dom";
import { getItem } from "../utility/localStorageControl";
import { lazy, useEffect } from "react";

import Home from "../container/home/Home";
import ViewCart from "../container/home/ViewCart";
import NotFound from "../container/NotFound/NotFound";
import OrderCompletePage from "../container/StaticPages/OrderCompletePage";
import HotelClosedPage from "../container/StaticPages/HotelClosedPage";

function Private() {
  return (
    <Routes>
      <Route exact path={`/`} element={<Home />} />
      <Route exact path={`/app/:table_id`} element={<Home />} />
      <Route exact path={`/app/:table_id/viewCart`} element={<ViewCart />} />
      <Route exact path={`/app/order-complete`} element={<OrderCompletePage />} />
      <Route exact path={`/app/hotel-closed`} element={<HotelClosedPage />} />
      <Route exact path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default Private;
