import Cookies from "js-cookie";
import { LOGIN } from "../../config/api";

// **  Initial State
const initialState = {
  userData: {},
  login: Cookies.get('logedIn'),
  // logoutStatus: false,
   loginStatus: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        userData: action.data,
        loginStatus: action.loginStatus,
      };
    default:
      return state;
  }
};

export default authReducer;
