import React from "react";
import { Col, Row, Content } from "antd";


function NotFound() {
  return (
    <>
      <div className="container">
        <h1>Not Found</h1>
      </div>

    </>
  );
}
export default NotFound;
