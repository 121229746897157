import axios from "axios";
import { message } from "antd";

import { getItem } from "../../utility/localStorageControl";
const API_ENDPOINT = "https://api.posease.com/api/";
const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path = "", message = {}) {
    return client({
      method: "GET",
      url: path,
      message: {},
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: data,
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  static delete(path = "") {
    return client({
      method: "DELETE",
      url: path,
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    if (response.data.data && Array.isArray(response.data.data)) {
      // Hide Success messages on list api
      // n.close();
      // typeof yourVariable === "object";
    } else if (response.config.method === "get") {
    } else {
      if (response.data.messageCode == "QR_ORDER_ADD") {
        message.success({
          content: "Order created",
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      } else {
        message.success({
          content: response.data.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }
    }
    return response;
  },
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      // if (response.status === 500) {
      //   // To Do
      // } else if (response.status === 400) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // } else if (response.status === 403) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // } else if (response.status === 404) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // }
      // else if (response.status === 422) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // }
      // else if (response.status === 401) {
      //   message.error({
      //     content: response.data.message,
      //     style: {
      //       float: "center",
      //       marginTop: "2vh",
      //     },
      //   });
      // } else {
      //   return originalRequest;
      // }
      message.error({
        content: response.data.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
      return response.data;
    } else {
      message.error({
        content: `Something went wrong. Please check your internet connection and try again!`,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
    return Promise.reject(error);
  }
);
export { DataService };
