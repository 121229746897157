import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import "../../css/custom.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Dropdown,
  Space,
  Skeleton,
  Modal,
  Radio,
  InputNumber,
} from "antd";
import { NavLink } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CaretRightOutlined,
  SearchOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { menuItemsListData } from "../../redux/HomePage/actionCreater";
import dish1 from "../../Images/Home/dish1.png";
import menuIcon from "../../Images/Home/menuBook.svg";
import dish4 from "../../Images/Home/dish4.jpg";
import { getItem, setItem } from "../../utility/localStorageControl";
import NoFoodItem from "../NotFound/NoFoodItem";

const { TextArea } = Input;
function ProductDetail({
  productListOfdata,
  productCategory,
  setSelectedProduct,
  selectedProduct,
}) {
  const currency = getItem("Currency");
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [listLimit, setListLimit] = useState(10);
  const [productDetailsUpdate, setProductDetails] = useState();

  let [productList, setProductList] = useState(
    productListOfdata ? productListOfdata : []
  );
  const [readMore, setReadMore] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [productId, setProductId] = useState("");
  const [productIdArr, setProductIdArr] = useState([]);

  useEffect(() => {
    if (productListOfdata !== []) {
      setProductList(productListOfdata);
    }
  }, [productCategory, productListOfdata]);

  const fetchMoreData = () => {
    setListLimit(listLimit + 10);
  };

  const showDrawer = (productdetails) => {
    setOpen(true);
    (productdetails && productdetails.option_variant_group?.length > 0) ||
    productdetails.option_addon_group?.length > 0 ||
    productdetails.option_item_group?.length > 0
      ? setDisableBtn(true)
      : setDisableBtn(false);
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    let details = JSON.parse(JSON.stringify(productdetails));
    form.setFieldsValue({
      varient_id: "",
      addon_id: "",
    });
    if (
      details.option_variant_group.length > 0 ||
      details.option_addon_group.length > 0 ||
      details.option_item_group.length > 0
    ) {
      let isVarience = false;
      let isAddon = false;
      let isAddon1st = false;
      let isAddon2nd = false;
      let isAddon3rd = false;
      let isAddon4rd = false;
      let isAddon5rd = false;
      let product_variants = [];
      let isAddon1stOptions = [];
      let isAddon2ndOptions = [];
      let isAddon3rdOptions = [];
      let isAddon4rdOptions = [];
      let isAddon5rdOptions = [];
      let AddonOptions = [];

      if (details.option_variant_group.length >= 1) {
        isVarience = true;
        product_variants = details.option_variant_group[0].product_variants;
        product_variants = product_variants.map((v) => ({
          ...v,
          isSelected: false,
        }));
        product_variants.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        details.option_variant_group[0].product_variants = product_variants;
      }

      if (details.option_variant_group.length >= 2) {
        isVarience = true;
        product_variants = details.option_variant_group[1].product_variants;
        product_variants = product_variants.map((v) => ({
          ...v,
          isSelected: false,
        }));

        product_variants.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        details.option_variant_group[1].product_variants = product_variants;
      }

      if (details.option_variant_group.length >= 3) {
        isVarience = true;
        product_variants = details.option_variant_group[2].product_variants;
        product_variants = product_variants.map((v) => ({
          ...v,
          isSelected: false,
        }));

        product_variants.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        details.option_variant_group[2].product_variants = product_variants;
      }

      if (details.option_variant_group.length >= 4) {
        isVarience = true;
        product_variants = details.option_variant_group[3].product_variants;
        product_variants = product_variants.map((v) => ({
          ...v,
          isSelected: false,
        }));

        product_variants.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        details.option_variant_group[3].product_variants = product_variants;
      }
      if (details.option_variant_group.length >= 5) {
        isVarience = true;
        product_variants = details.option_variant_group[4].product_variants;
        product_variants = product_variants.map((v) => ({
          ...v,
          isSelected: false,
        }));

        product_variants.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        details.option_variant_group[4].product_variants = product_variants;
      }

      if (details.option_addon_group.length > 0) {
        isAddon = true;
      }

      if (details.option_addon_group.length >= 1) {
        isAddon1st = true;
        isAddon1stOptions = details.option_addon_group[0].product_addons;
        isAddon1stOptions = isAddon1stOptions.map((v) => ({
          ...v,
          isSelected: false,
        }));

        isAddon1stOptions.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
        details.option_addon_group[0].product_addons = isAddon1stOptions;
      }

      if (details.option_addon_group.length >= 2) {
        isAddon2nd = true;
        isAddon2ndOptions = details.option_addon_group[1].product_addons;
        isAddon2ndOptions = isAddon2ndOptions.map((v) => ({
          ...v,
          isSelected: false,
        }));

        isAddon2ndOptions.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
        details.option_addon_group[1].product_addons = isAddon2ndOptions;
      }

      if (details.option_addon_group.length >= 3) {
        isAddon3rd = true;
        isAddon3rdOptions = details.option_addon_group[2].product_addons;
        isAddon3rdOptions = isAddon3rdOptions.map((v) => ({
          ...v,
          isSelected: false,
        }));

        isAddon3rdOptions.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
        details.option_addon_group[2].product_addons = isAddon3rdOptions;
      }
      if (details.option_addon_group.length >= 4) {
        isAddon4rd = true;
        isAddon4rdOptions = details.option_addon_group[3].product_addons;
        isAddon4rdOptions = isAddon4rdOptions.map((v) => ({
          ...v,
          isSelected: false,
        }));

        isAddon3rdOptions.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
        details.option_addon_group[3].product_addons = isAddon4rdOptions;
      }
      if (details.option_addon_group.length >= 5) {
        isAddon5rd = true;
        isAddon5rdOptions = details.option_addon_group[4].product_addons;
        isAddon5rdOptions = isAddon5rdOptions.map((v) => ({
          ...v,
          isSelected: false,
        }));

        isAddon3rdOptions.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
        details.option_addon_group[4].product_addons = isAddon5rdOptions;
      }
      AddonOptions = [
        ...isAddon1stOptions,
        ...isAddon2ndOptions,
        ...isAddon3rdOptions,
        ...isAddon4rdOptions,
        ...isAddon5rdOptions,
      ];

      if (details.option_addon_group) {
        details.option_addon_group.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
      }

      details.option_variant_group.sort((a, b) =>
        a.sort_order > b.sort_order ? 1 : -1
      );

      //option_variant_group = option_variant_group.map(v => ({...v, isActive: true}))
      let taxes = 0;
      details.tax_group.taxes.map((data) => {
        taxes += data.tax_percentage;
      });
      let Details = {
        key: details._id,
        id: details._id,
        item: details.product_name,
        display_name: details.product_name,
        price: details.price,
        calculatedprice: details.price,
        quantity: 1,
        productInclusivePrice: details.productInclusivePrice,
        productInclusivePricecalculatedprice: details.productInclusivePrice,
        newqty: 1,
        option_variant_group: details.option_variant_group,
        option_addon_group: details.option_addon_group,
        cal: details.calculatedprice,
        qty: details.quantity,
        new_item: true,
        isAddon: isAddon,
        isAddon1st: isAddon1st,
        isAddon2nd: isAddon2nd,
        isAddon3rd: isAddon3rd,
        AddonOptions: AddonOptions,
        isVarience: isVarience,
        variance_price: 0,
        variance_object: {},
        productTaxes: details.tax_group.Totaltax,
        taxGroup: details.tax_group,
        option_item_group: details.option_item_group,
        option_status: details.option_status,
        order_ticket_group: details.product_category.order_ticket_group,
        notes: details.notes,
        add_or_remove: "Added Items",
        product_category: details.product_category.category_name,
        orderTiketsNotes:
          details.orderTiketsNotes !== "" ? details.orderTiketsNotes : "",
        product_img:
          details.product_img !== "" && details.product_img !== undefined
            ? details.product_img
            : "",
      };
      // console.log('Details::::::--->', Details)
      setProductDetails(Details);
      return true;
    } else {
      let isVarience = false;
      let isAddon = false;
      let isAddon1st = false;
      let isAddon2nd = false;
      let isAddon3rd = false;
      let AddonOptions = [];

      let product = {
        key: details._id,
        id: details._id,
        key_price: 0,
        item: details.product_name,
        display_name: details.product_name,
        productInclusivePrice: details.productInclusivePrice,
        productInclusivePricecalculatedprice: details.productInclusivePrice,
        productInclusivePriceKeyPrice: 0,
        price: details.price,
        calculatedprice: details.price,
        quantity: 1,
        newqty: 1,
        notes: details.notes,
        option_variant_group: details.option_variant_group,
        option_addon_group: details.option_addon_group,
        cal: details.calculatedprice,
        new_item: true,
        isAddon: isAddon,
        isAddon1st: isAddon1st,
        isAddon2nd: isAddon2nd,
        isAddon3rd: isAddon3rd,
        AddonOptions: AddonOptions,
        isVarience: isVarience,
        variance_price: 0,
        variance_object: {},
        productTaxes: details.tax_group.Totaltax,
        taxGroup: details.tax_group,
        option_item_group: details.option_item_group,
        add_or_remove: "Added Items",
        order_ticket_group: details.product_category.order_ticket_group,
        product_category: details.product_category.category_name,
        orderTiketsNotes:
          details.orderTiketsNotes !== "" ? details.orderTiketsNotes : "",
        product_img:
          details.product_img !== "" && details.product_img !== undefined
            ? details.product_img
            : "",
      };
      setProductDetails(product);
    }
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setDisableBtn(false);
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };

  useEffect(() => {
    if (productId && readMore == true) {
      setProductIdArr((state) => state.concat(productId));
    }
  }, [productId, readMore]);

  function productDetails(productdetails, click) {
    let details = JSON.parse(JSON.stringify(productdetails));
    const getProductAddedIndex = selectedProduct.findIndex(
      (product) => product.key === details.key
    );
    if (getProductAddedIndex === -1) {
      let pro = [details, ...selectedProduct];
      setSelectedProduct(pro);
      setItem("product_Details", pro);
    } else {
      selectedProduct[getProductAddedIndex]["quantity"] =
        selectedProduct[getProductAddedIndex]["quantity"] + details.quantity;
      selectedProduct[getProductAddedIndex]["newqty"] =
        selectedProduct[getProductAddedIndex]["newqty"] + details.quantity;
      selectedProduct[getProductAddedIndex].calculatedprice =
        selectedProduct[getProductAddedIndex].quantity *
        selectedProduct[getProductAddedIndex].price;
      if (
        selectedProduct[getProductAddedIndex].productInclusivePrice != undefined
      ) {
        selectedProduct[
          getProductAddedIndex
        ].productInclusivePricecalculatedprice =
          selectedProduct[getProductAddedIndex].quantity *
          (selectedProduct[getProductAddedIndex].productInclusivePriceKeyPrice
            ? selectedProduct[getProductAddedIndex]
                .productInclusivePriceKeyPrice
            : selectedProduct[getProductAddedIndex].productInclusivePrice);
      }
      let pro = [...selectedProduct];
      setSelectedProduct(pro);
      setItem("product_Details", pro);
    }
  }

  const onSubmit = async (value) => {
    productDetails(productDetailsUpdate);
    setOpen(false);
    form.resetFields();
    setDisableBtn(false);
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };

  function calQty(idodproduct) {
    if (selectedProduct.length > 0) {
      let totalQuantity = 0;
      let CurrentProduct = selectedProduct?.filter(
        (value) => value.id === idodproduct
      );

      if (CurrentProduct.length > 0) {
        CurrentProduct.map((value) => (totalQuantity += value.quantity));
        return `${totalQuantity} x `;
      }
    }
  }
  const productObjectCreate = (product, index) => {
    let value = JSON.parse(JSON.stringify(product));
    // console.log("🚀 ~ file: ProductDetail.js:399 ~ productObjectCreate ~ value:", value)
    let productShow = true;

    if (product && product._id) {
      if (value.priceBook?.length > 0) {
        let pricebookDetails = value.priceBook.find((val) => {
          let table_type = getItem("hotel_room") ? "room_order" : "qr_order";
          if (
            (val.orderType == table_type || val.orderType == "all_orders") &&
            getItem("register_id") == val.registerAssignedTo
          ) {
            return val;
          }
        });
        if (pricebookDetails) {
          value.price = pricebookDetails.priceBookPrice;

          if (pricebookDetails.disable) {
            productShow = false;
          }
        }
      }
      let orignalPrice = value.price;
      let totalTax = 0;
      value.price = Number(value.price);
      if (value.option_status === "combo") {
        value.newPrice = Number(value.price);
        // if (value.option_item_group.length > 0) {
        //   value.option_item_group.map((item) => {
        //     let minimumArray = [];
        //     item.products.map((value) => {
        //       let FilterVarints = item.product_variants.filter(
        //         (data) => data.product_id._id === value._id
        //       );
        //       if (FilterVarints.length > 0) {
        //         FilterVarints.map((variant) => {
        //           minimumArray.push(
        //             variant.product_id.price + variant.variant_id.price
        //           );
        //         });
        //       } else {
        //         minimumArray.push(value.price);
        //       }
        //     });
        //     let itemMinPrice = Math.min.apply(Math, minimumArray);
        //     itemPrice += itemMinPrice;
        //   });
        //   value.newPrice = Number(value.price + itemPrice);
        // } else {
        //   value.newPrice = Number(value.price);
        // }
      } else {
        if (value.option_variant_group.length > 0) {
          let varintsPrice = 0;

          value.option_variant_group.map((varints, index) => {
            let minimumArray = [];
            let totalVarinats = varints.product_variants.length;
            let disableVarints = 0;
            varints.product_variants.map((variant) => {
              if (variant.priceBook?.length > 0) {
                let tabletype = getItem("hotel_room")
                  ? "room_order"
                  : "qr_order";
                let pricebookDetails = variant.priceBook.find((val) => {
                  if (
                    (val.orderType == tabletype ||
                      val.orderType == "all_orders") &&
                    getItem("register_id") == val.registerAssignedTo
                  ) {
                    return val;
                  }
                });

                if (pricebookDetails) {
                  variant.price = pricebookDetails.priceBookPrice;
                  if (pricebookDetails.disable) {
                    disableVarints = disableVarints + 1;
                    variant.disable = true;
                  }
                }
              }
              if (variant.disable == undefined || variant.disable == false) {
                minimumArray.push(variant.price);
              }
            });
            if (disableVarints == totalVarinats) {
              value.option_variant_group.splice(index, 1);
            } else {
              let variantMinPrice = minimumArray.length
                ? Math.min.apply(Math, minimumArray)
                : 0;
              varintsPrice += variantMinPrice;
            }
          });
          if (varintsPrice > 0) {
            value.newPrice = Number(value.price + varintsPrice);
          }
        } else {
          value.newPrice = Number(value.price);
        }
        if (value.option_addon_group.length > 0) {
          value.option_addon_group.map((addons, index) => {
            let totalAddons = addons.product_addons.length;
            let disableAddon = 0;
            addons.product_addons.map((addon) => {
              if (addon.priceBook?.length > 0) {
                let tabletype = getItem("hotel_room")
                  ? "room_order"
                  : "qr_order";
                let pricebookDetails = addon.priceBook.find((val) => {
                  if (
                    (val.orderType == tabletype ||
                      val.orderType == "all_orders") &&
                    getItem("register_id") == val.registerAssignedTo
                  ) {
                    return val;
                  }
                });

                if (pricebookDetails) {
                  console.log("howcanCheckdetails", pricebookDetails);
                  addon.price = pricebookDetails.priceBookPrice;
                  if (pricebookDetails.disable) {
                    disableAddon = disableAddon + 1;
                    addon.disable = true;
                  }
                }
              }
            });
            if (totalAddons == disableAddon) {
              value.option_addon_group.splice(index, 1);
            }
          });
        }
      }

      if (value.product_name.length > 30) {
        let divideArray = value.product_name.match(/.{1,30}/g);
        value.Newproduct_name = value.product_name.replace(
          divideArray[1],
          ".."
        );
      }

      value &&
        value.tax_group &&
        value.tax_group.taxes &&
        value.tax_group.taxes.map((tax) => (totalTax += tax.tax_percentage));

      if (value?.tax_group) {
        value.tax_group.Totaltax = totalTax;
      }

      if (
        value.tax_group !== null &&
        value.tax_group.taxes_inclusive_in_product_price
      ) {
        value.productInclusivePrice = Number(orignalPrice);

        if (value?.option_variant_group?.length > 0) {
          value?.option_variant_group.map((val) => {
            val.product_variants.map((j) => {
              j.productInclusivePrice = j.price;
              let price1;
              if (totalTax === 0) {
                j.price = Number(j.price);
              } else {
                let total2;
                let price2;
                let price3;
                price1 = j.price * totalTax;
                total2 = 100 + totalTax;
                price2 = price1 / total2;
                price3 = Number(j.price - price2);
                j.price = Number(price3);
              }
            });
          });
        }
        if (value?.option_addon_group?.length > 0) {
          value?.option_addon_group.map((val) => {
            val.product_addons.map((j) => {
              j.productInclusivePrice = j.price;
              let price1;
              if (totalTax === 0) {
                j.price = Number(j.price);
              } else {
                let total2;
                let price2;
                let price3;
                price1 = j.price * totalTax;
                total2 = 100 + totalTax;
                price2 = price1 / total2;
                price3 = Number(j.price - price2);
                j.price = Number(price3);
              }
            });
          });
        }
        if (value.price === 0) {
          value.price = value.price;
        } else {
          let price1;
          if (totalTax === 0) {
            value.price = Number(value.price);
          } else {
            let total2;
            let price2;
            let price3;
            price1 = value.price * totalTax;
            total2 = 100 + totalTax;
            price2 = price1 / total2;
            price3 = Number(value.price - price2);
            value.price = Number(price3);
          }
        }
      }

      const productDescription =
        value.product_description &&
        value.product_description != "" &&
        value.product_description;
      // console.log("valueeeeeeeee~~~~~>>>:", value)
      if (productShow) {
        return (
          <>
            <Col
              xxl={6}
              xl={8}
              lg={8}
              md={12}
              sm={12}
              xs={24}
              className={styles.cardColumns}
            >
              <div className={styles.productCardBox}>
                <div className={styles.categoryOptionBox}>
                  <div className={styles.productOption}>
                    <div
                      className={
                        value.product_type
                          ? value.product_type == "other"
                            ? styles.eggFood
                            : value.product_type == "non-veg"
                            ? styles.nonVegFood
                            : styles.vegFood
                          : styles.vegFood
                      }
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className={styles.categoryType}>
                    <p>{value.product_category.category_name}</p>
                  </div>
                </div>
                <div className={styles.MenuItemContainer}>
                  <div className={styles.sideImgbox}>
                    {Object.keys(value).includes("product_img") == true &&
                    value.product_img !== "" &&
                    value.product_img !== undefined ? (
                      <div className={styles.itemImageBox}>
                        <div className={styles.productItemImage}>
                          <img src={value.product_img} alt="product img"></img>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={styles.addItemBox}>
                      <div className={styles.addBtn}>
                        <Button onClick={() => showDrawer(value)}>ADD</Button>
                      </div>
                    </div>
                  </div>
                  <div className={styles.itemAllDescription}>
                    <div className={styles.descriptionHead}>
                      <h2>
                        {value.Newproduct_name
                          ? value.Newproduct_name
                          : value.product_name}
                      </h2>
                    </div>

                    <div className={styles.itemPrice}>
                      <h3 className={styles.price}>
                        {calQty(value._id)}
                        {`${currency}${
                          value.newPrice ? value.newPrice : value.price
                        }`}
                        {value.option_addon_group?.length > 0 ||
                        value.option_item_group?.length > 0 ||
                        value.option_variant_group?.length > 0 ? (
                          <span> +</span>
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>

                    {productDescription !== "" &&
                    productDescription !== undefined &&
                    productDescription !== null ? (
                      readMore == true &&
                      productIdArr &&
                      productIdArr.includes(value._id) == true ? (
                        <p className={styles.description}>
                          {productDescription !== "" && productDescription}
                        </p>
                      ) : productDescription &&
                        productDescription.length > 50 ? (
                        <p className={styles.description}>
                          {productDescription.slice(0, 50) + "..."}
                          <span
                            className={styles.readMoreOption}
                            onClick={() => {
                              setReadMore(true);
                              setProductId(value._id);
                            }}
                          >
                            Read More
                          </span>
                        </p>
                      ) : (
                        <p className={styles.description}>
                          {productDescription !== "" && productDescription}
                        </p>
                      )
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            </Col>

            {/* <Col xxl={6} xl={8} lg={8} md={12} sm={12} xs={24}>
              <div className={styles.allMenuItemContainer}>
                <div className={styles.sideImgContainer}>
                  {Object.keys(value).includes("product_img") == true &&
                    value.product_img !== "" &&
                    value.product_img !== undefined ? (
                    <div className={styles.itemImageBox}>
                      <div className={styles.itemImage}>
                        <img src={value.product_img} alt="product img"></img>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className={styles.addItemBox}>
                    <div className={styles.addBtn}>
                      <Button onClick={() => showDrawer(value)}>ADD</Button>
                    </div>
                  </div>
                </div>
                <div className={styles.itemDescription}>
                  <div className={styles.productOption}>
                    <div
                      className={
                        value.product_type
                          ? value.product_type == "other"
                            ? styles.eggFood
                            : value.product_type == "non-veg"
                              ? styles.nonVegFood
                              : styles.vegFood
                          : styles.vegFood
                      }
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className={styles.descriptionHead}>
                    <h2>
                      {value.Newproduct_name
                        ? value.Newproduct_name
                        : value.product_name}
                    </h2>
                  </div>

                  <div className={styles.itemPrice}>
                    <h3 className={styles.price}>
                      {calQty(value._id)}
                      {`${currency}${value.newPrice ? value.newPrice : value.price
                        }`}
                      {value.option_addon_group?.length > 0 ||
                        value.option_item_group?.length > 0 ||
                        value.option_variant_group?.length > 0 ? (
                        <span> +</span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                  <div className={styles.categoryType}>
                    <p>{value.product_category.category_name}</p>
                  </div>
                  {readMore == true ? (
                    <p className={styles.descriptionText}>
                      {productDescription !== "" && productDescription}
                    </p>
                  ) : productDescription && productDescription.length > 50 ? (
                    <p className={styles.descriptionText}>
                      {productDescription.slice(0, 50) + "..."}
                      <span
                        className={styles.readMoreOption}
                        onClick={() => setReadMore(true)}
                      >
                        Read More
                      </span>
                    </p>
                  ) : (
                    <p className={styles.descriptionText}>
                      {productDescription !== "" && productDescription}
                    </p>
                  )}
                </div>
              </div>
            </Col> */}
          </>
        );
      }
    }
  };
  function getProductPrice(product) {
    let price = 0;
    if (product.isVarience) {
      price = Number(product.key_price);
    } else {
      price = Number(product.price);
    }
    return price;
  }

  function changeQuantity(product, check, value) {
    if (product.quantity == 1 && check == "minus") {
      return true;
    }
    let qty =
      check == "number"
        ? value
        : check == "add"
        ? product["quantity"] + 1
        : product["quantity"] - 1;
    product["quantity"] = qty;
    product["newqty"] = qty;
    product.calculatedprice = product.quantity * getProductPrice(product);
    if (product.productInclusivePrice != undefined) {
      product.productInclusivePricecalculatedprice =
        product.quantity *
        (product.productInclusivePriceKeyPrice
          ? product.productInclusivePriceKeyPrice
          : product.productInclusivePrice);
    }
    setProductDetails({ ...product });
  }
  // variant calculation
  // CalCulation
  function setProductKeyAndCalculateTotalAndDisplayName(productDetails) {
    let keyNames = [productDetails.item];
    let KeyIds = [productDetails.id];

    let selecetdItem1 = [];

    if (productDetails.option_item_group != undefined) {
      if (productDetails.option_item_group[0] != undefined) {
        if (productDetails.option_item_group[0].selecet == "product") {
          selecetdItem1 = productDetails.option_item_group[0].products.filter(
            (itm) => itm.isSelected == true
          );
        } else {
          selecetdItem1 =
            productDetails.option_item_group[0].product_variants.filter(
              (itm) => itm.isSelected == true
            );
        }
      }
    }

    let selecetdItem2 = [];

    if (productDetails.option_item_group !== undefined) {
      if (productDetails.option_item_group[1] !== undefined) {
        if (productDetails.option_item_group[1].selecet == "product") {
          selecetdItem2 = productDetails.option_item_group[1].products.filter(
            (itm) => itm.isSelected == true
          );
        } else {
          selecetdItem2 =
            productDetails.option_item_group[1].product_variants.filter(
              (itm) => itm.isSelected == true
            );
        }
      }
    }

    let selecetdItem3 = [];

    if (productDetails.option_item_group != undefined) {
      if (productDetails.option_item_group[2] != undefined) {
        if (productDetails.option_item_group[2].selecet == "product") {
          selecetdItem3 = productDetails.option_item_group[2].products.filter(
            (itm) => itm.isSelected == true
          );
        } else {
          selecetdItem3 =
            productDetails.option_item_group[2].product_variants.filter(
              (itm) => itm.isSelected == true
            );
        }
      }
    }

    let selecetdItem4 = [];

    if (productDetails.option_item_group != undefined) {
      if (productDetails.option_item_group[3] != undefined) {
        if (productDetails.option_item_group[3].selecet == "product") {
          selecetdItem4 = productDetails.option_item_group[3].products.filter(
            (itm) => itm.isSelected == true
          );
        } else {
          selecetdItem4 =
            productDetails.option_item_group[3].product_variants.filter(
              (itm) => itm.isSelected == true
            );
        }
      }
    }

    let selecetdItem5 = [];

    if (productDetails.option_item_group != undefined) {
      if (productDetails.option_item_group[4] != undefined) {
        if (productDetails.option_item_group[4].selecet == "product") {
          selecetdItem5 = productDetails.option_item_group[4].products.filter(
            (itm) => itm.isSelected == true
          );
        } else {
          selecetdItem5 =
            productDetails.option_item_group[4].product_variants.filter(
              (itm) => itm.isSelected == true
            );
        }
      }
    }

    let selectedVarient = [];
    if (productDetails.option_variant_group[0] !== undefined) {
      selectedVarient =
        productDetails.option_variant_group[0].product_variants.filter(
          function (itm) {
            return itm.isSelected == true;
          }
        );
    }

    let selectedVarient1 = [];

    if (productDetails.option_variant_group[1] !== undefined) {
      selectedVarient1 =
        productDetails.option_variant_group[1].product_variants.filter(
          function (itm) {
            return itm.isSelected == true;
          }
        );
    }

    let selectedVarient2 = [];

    if (productDetails.option_variant_group[2] !== undefined) {
      selectedVarient2 =
        productDetails.option_variant_group[2].product_variants.filter(
          function (itm) {
            return itm.isSelected == true;
          }
        );
    }

    let selectedVarient3 = [];

    if (productDetails.option_variant_group[3] !== undefined) {
      selectedVarient3 =
        productDetails.option_variant_group[3].product_variants.filter(
          function (itm) {
            return itm.isSelected == true;
          }
        );
    }

    let selectedVarient4 = [];
    if (productDetails.option_variant_group[4] !== undefined) {
      selectedVarient4 =
        productDetails.option_variant_group[4].product_variants.filter(
          function (itm) {
            return itm.isSelected == true;
          }
        );
    }

    // step 2 set final price in case of product is varient or not
    if (selectedVarient.length > 0) {
      productDetails.key_price =
        productDetails.price + selectedVarient[0].price;

      if (
        productDetails.productInclusivePrice != undefined &&
        selectedVarient[0].productInclusivePrice != undefined
      ) {
        productDetails.productInclusivePriceKeyPrice =
          productDetails.productInclusivePrice +
          selectedVarient[0].productInclusivePrice;
      }

      selectedVarient.forEach(function (item, index) {
        keyNames.push(` / ${item.variant_name}`);
        KeyIds.push("-varient-" + item._id);
      });
    } else {
      productDetails.key_price = productDetails.price;
      if (productDetails.productInclusivePrice != undefined) {
        productDetails.productInclusivePriceKeyPrice =
          productDetails.productInclusivePrice;
      }
    }
    // step 2.1
    if (selectedVarient1.length > 0) {
      productDetails.key_price =
        productDetails.key_price + selectedVarient1[0].price;
      if (
        productDetails.productInclusivePrice != undefined &&
        selectedVarient1[0].productInclusivePrice != undefined
      ) {
        productDetails.productInclusivePriceKeyPrice =
          productDetails.productInclusivePriceKeyPrice +
          selectedVarient1[0].productInclusivePrice;
      }
      selectedVarient1.forEach(function (item, index) {
        keyNames.push(` / ${item.variant_name}`);
        KeyIds.push("-varient-" + item._id);
      });
    }

    // step 2.2
    if (selectedVarient2.length > 0) {
      productDetails.key_price =
        productDetails.key_price + selectedVarient2[0].price;
      if (
        productDetails.productInclusivePrice != undefined &&
        selectedVarient2[0].productInclusivePrice != undefined
      ) {
        productDetails.productInclusivePriceKeyPrice =
          productDetails.productInclusivePriceKeyPrice +
          selectedVarient2[0].productInclusivePrice;
      }
      selectedVarient2.forEach(function (item, index) {
        keyNames.push(` / ${item.variant_name}`);
        KeyIds.push("-varient-" + item._id);
      });
    }

    // step 2.3
    if (selectedVarient3.length > 0) {
      productDetails.key_price =
        productDetails.key_price + selectedVarient3[0].price;
      if (
        productDetails.productInclusivePrice != undefined &&
        selectedVarient3[0].productInclusivePrice != undefined
      ) {
        productDetails.productInclusivePriceKeyPrice =
          productDetails.productInclusivePriceKeyPrice +
          selectedVarient3[0].productInclusivePrice;
      }
      selectedVarient3.forEach(function (item, index) {
        keyNames.push(` / ${item.variant_name}`);
        KeyIds.push("-varient-" + item._id);
      });
    }
    // step 2.4
    if (selectedVarient4.length > 0) {
      productDetails.key_price =
        productDetails.key_price + selectedVarient4[0].price;
      if (
        productDetails.productInclusivePrice != undefined &&
        selectedVarient4[0].productInclusivePrice != undefined
      ) {
        productDetails.productInclusivePriceKeyPrice =
          productDetails.productInclusivePriceKeyPrice +
          selectedVarient4[0].productInclusivePrice;
      }
      selectedVarient4.forEach(function (item, index) {
        keyNames.push(` / ${item.variant_name}`);
        KeyIds.push("-varient-" + item._id);
      });
    }

    // step 3.1 item
    if (selecetdItem1.length > 0) {
      // if (selecetdItem1[0].variant_id) {
      //   productDetails.key_price =
      //     productDetails.key_price +
      //     selecetdItem1[0].product_id.price +
      //     selecetdItem1[0].variant_id.price;
      // } else {
      //   productDetails.key_price =
      //     productDetails.key_price + selecetdItem1[0].price;
      // }

      // productDetails.key_price =
      //   productDetails.key_price + productDetails.price;
      selecetdItem1.forEach(function (item, index) {
        if (item.variant_id) {
          keyNames.push(
            ` - ${item.product_id.product_name} / ${item.variant_id.variant_name}`
          );
          KeyIds.push("-item-" + item._id);
        } else {
          keyNames.push(` - ${item.product_name}`);
          KeyIds.push("-item-" + item._id);
        }
      });
    }
    if (selecetdItem2.length > 0) {
      // if (selecetdItem2[0].variant_id) {
      //   productDetails.key_price =
      //     productDetails.key_price +
      //     selecetdItem2[0].product_id.price +
      //     selecetdItem2[0].variant_id.price;
      // } else {
      //   productDetails.key_price =
      //     productDetails.key_price + selecetdItem2[0].price;
      // }
      // productDetails.key_price =
      //   productDetails.key_price + productDetails.price;
      selecetdItem2.forEach(function (item, index) {
        if (item.variant_id) {
          keyNames.push(
            ` - ${item.product_id.product_name} / ${item.variant_id.variant_name}`
          );
          KeyIds.push("-item-" + item._id);
        } else {
          keyNames.push(` - ${item.product_name}`);
          KeyIds.push("-item-" + item._id);
        }
      });
    }

    if (selecetdItem3.length > 0) {
      // if (selecetdItem3[0].variant_id) {
      //   productDetails.key_price =
      //     productDetails.key_price +
      //     selecetdItem3[0].product_id.price +
      //     selecetdItem3[0].variant_id.price;
      // } else {
      //   productDetails.key_price =
      //     productDetails.key_price + selecetdItem3[0].price;
      // }
      selecetdItem3.forEach(function (item, index) {
        if (item.variant_id) {
          keyNames.push(
            ` - ${item.product_id.product_name} / ${item.variant_id.variant_name}`
          );
          KeyIds.push("-item-" + item._id);
        } else {
          keyNames.push(` - ${item.product_name}`);
          KeyIds.push("-item-" + item._id);
        }
      });
    }

    if (selecetdItem4.length > 0) {
      // if (selecetdItem4[0].variant_id) {
      //   productDetails.key_price =
      //     productDetails.key_price +
      //     selecetdItem4[0].product_id.price +
      //     selecetdItem4[0].variant_id.price;
      // } else {
      //   productDetails.key_price =
      //     productDetails.key_price + selecetdItem4[0].price;
      // }
      selecetdItem4.forEach(function (item, index) {
        if (item.variant_id) {
          keyNames.push(
            ` / ${item.product_id.product_name} / ${item.variant_id.variant_name}`
          );
          KeyIds.push("-item-" + item._id);
        } else {
          keyNames.push(` - ${item.product_name}`);
          KeyIds.push("-item-" + item._id);
        }
      });
    }

    if (selecetdItem5.length > 0) {
      // if (selecetdItem5[0].variant_id) {
      //   productDetails.key_price =
      //     productDetails.key_price +
      //     selecetdItem5[0].product_id.price +
      //     selecetdItem5[0].variant_id.price;
      // } else {
      //   productDetails.key_price =
      //     productDetails.key_price + selecetdItem5[0].price;
      // }
      selecetdItem5.forEach(function (item, index) {
        if (item.variant_id) {
          keyNames.push(
            ` - ${item.product_id.product_name} / ${item.variant_id.variant_name}`
          );
          KeyIds.push("-item-" + item._id);
        } else {
          keyNames.push(` - ${item.product_name}`);
          KeyIds.push("-item-" + item._id);
        }
      });
    }

    if (
      productDetails.option_addon_group &&
      productDetails.option_addon_group[0] !== undefined
    ) {
      productDetails.option_addon_group[0].product_addons.forEach(function (
        item,
        index
      ) {
        if (item.isSelected) {
          productDetails.key_price = productDetails.key_price + item.price;
          if (productDetails.productInclusivePrice != undefined) {
            productDetails.productInclusivePriceKeyPrice =
              productDetails.productInclusivePriceKeyPrice +
              item.productInclusivePrice;
          }
          keyNames.push(`+${item.addon_name}`);
          KeyIds.push("-addon-" + item._id);
        }
      });
    }

    if (
      productDetails.option_addon_group &&
      productDetails.option_addon_group[1] !== undefined
    ) {
      productDetails.option_addon_group[1].product_addons.forEach(function (
        item,
        index
      ) {
        if (item.isSelected) {
          productDetails.key_price = productDetails.key_price + item.price;
          if (productDetails.productInclusivePrice != undefined) {
            productDetails.productInclusivePriceKeyPrice =
              productDetails.productInclusivePriceKeyPrice +
              item.productInclusivePrice;
          }
          keyNames.push(`+${item.addon_name}`);
          KeyIds.push("-addon-" + item._id);
        }
      });
    }
    if (
      productDetails.option_addon_group &&
      productDetails.option_addon_group[2] !== undefined
    ) {
      productDetails.option_addon_group[2].product_addons.forEach(function (
        item,
        index
      ) {
        if (item.isSelected) {
          productDetails.key_price = productDetails.key_price + item.price;
          if (productDetails.productInclusivePrice != undefined) {
            productDetails.productInclusivePriceKeyPrice =
              productDetails.productInclusivePriceKeyPrice +
              item.productInclusivePrice;
          }
          keyNames.push(`+${item.addon_name}`);
          KeyIds.push("-addon-" + item._id);
        }
      });
    }

    if (
      productDetails.option_addon_group &&
      productDetails.option_addon_group[3] !== undefined
    ) {
      productDetails.option_addon_group[3].product_addons.forEach(function (
        item,
        index
      ) {
        if (item.isSelected) {
          productDetails.key_price = productDetails.key_price + item.price;
          if (productDetails.productInclusivePrice != undefined) {
            productDetails.productInclusivePriceKeyPrice =
              productDetails.productInclusivePriceKeyPrice +
              item.productInclusivePrice;
          }
          keyNames.push(`+${item.addon_name}`);
          KeyIds.push("-addon-" + item._id);
        }
      });
    }
    if (
      productDetails.option_addon_group &&
      productDetails.option_addon_group[4] !== undefined
    ) {
      productDetails.option_addon_group[4].product_addons.forEach(function (
        item,
        index
      ) {
        if (item.isSelected) {
          productDetails.key_price = productDetails.key_price + item.price;
          if (productDetails.productInclusivePrice != undefined) {
            productDetails.productInclusivePriceKeyPrice =
              productDetails.productInclusivePriceKeyPrice +
              item.productInclusivePrice;
          }
          keyNames.push(`+${item.addon_name}`);
          KeyIds.push("-addon-" + item._id);
        }
      });
    }

    productDetails.calculatedprice =
      productDetails.quantity * productDetails.key_price;

    if (productDetails.productInclusivePrice != undefined) {
      productDetails.productInclusivePricecalculatedprice =
        productDetails.quantity * productDetails.productInclusivePriceKeyPrice;
    }
    productDetails.display_name = keyNames;
    productDetails.key = KeyIds.join("-");
    return productDetails;
  }

  //changes Variant group
  const handleVarintGroup = (id, varintGroupIndex, variantIndex) => {
    productDetailsUpdate.option_variant_group[
      varintGroupIndex
    ].product_variants.map((h) => {
      if (h._id == id) {
        h.isSelected = true;
        setDisableBtn(false);
      } else {
        h.isSelected = false;
      }
    });
    let latestProduct =
      setProductKeyAndCalculateTotalAndDisplayName(productDetailsUpdate);
    setProductDetails({ ...latestProduct });
  };

  // Changes AddonGroup
  const handleAddonGroup = (addon, addonGroupIndex, addonIndex) => {
    productDetailsUpdate.option_addon_group[addonGroupIndex].product_addons[
      addonIndex
    ].isSelected =
      !productDetailsUpdate.option_addon_group[addonGroupIndex].product_addons[
        addonIndex
      ].isSelected;
    let latestProduct =
      setProductKeyAndCalculateTotalAndDisplayName(productDetailsUpdate);
    setProductDetails({
      ...latestProduct,
    });
  };

  //item Group Calculation
  function handleItemGroup(e, itemGroupIndex) {
    const id = e.target.value.split(" ");
    if (id[1] === "product") {
      productDetailsUpdate.option_item_group[itemGroupIndex].products.forEach(
        function (item, index) {
          if (item._id == id[0]) {
            productDetailsUpdate.option_item_group[itemGroupIndex].products[
              index
            ].isSelected = true;
            productDetailsUpdate.option_item_group[itemGroupIndex].selecet =
              "product";
          } else {
            productDetailsUpdate.option_item_group[itemGroupIndex].products[
              index
            ].isSelected = false;
            productDetailsUpdate.option_item_group[itemGroupIndex].selecet =
              "product";
          }
        }
      );
    } else {
      productDetailsUpdate.option_item_group[
        itemGroupIndex
      ].product_variants.forEach(function (item, index) {
        if (item.variant_id._id == id[0]) {
          productDetailsUpdate.option_item_group[
            itemGroupIndex
          ].product_variants[index].isSelected = true;
          productDetailsUpdate.option_item_group[itemGroupIndex].selecet =
            "variant";
        } else {
          productDetailsUpdate.option_item_group[
            itemGroupIndex
          ].product_variants[index].isSelected = false;
          productDetailsUpdate.option_item_group[itemGroupIndex].selecet =
            "variant";
        }
      });
    }
    let latestProduct = setProductKeyAndCalculateTotalAndDisplayName(
      productDetailsUpdate,
      productDetailsUpdate.option_item_group
    );

    setProductDetails({
      ...latestProduct,
    });
  }

  // for instruction
  const instructionAdded = (product, instruction, index) => {
    instruction !== null && instruction !== "" && instruction !== undefined
      ? (product.orderTiketsNotes = instruction)
      : (product.orderTiketsNotes = "");
    setProductDetails({ ...product });
  };

  return (
    <>
      {productList.length > 0 ? (
        productCategory === "123" ? (
          <InfiniteScroll
            className={styles.scrollComponent}
            dataLength={productList.slice(0, listLimit).length}
            next={fetchMoreData}
            hasMore={true}
            // loader={<h4>Loading...</h4>}
          >
            {productList.slice(0, listLimit).map(productObjectCreate)}
          </InfiniteScroll>
        ) : (
          productList.map(productObjectCreate)
        )
      ) : (
        // <p>No Products</p>
        <NoFoodItem />
      )}
      {productDetailsUpdate && (
        <div className="addItemDrawer">
          <Drawer
            className={
              productDetailsUpdate.option_variant_group?.length > 0 ||
              productDetailsUpdate.option_addon_group?.length > 0 ||
              productDetailsUpdate.option_item_group?.length > 0
                ? ""
                : "itemDrawerClass"
            }
            title={
              <div className={styles.addItemImgContainer}>
                {productDetailsUpdate &&
                productDetailsUpdate.product_img !== "" &&
                productDetailsUpdate.product_img !== undefined &&
                productDetailsUpdate.product_img !== false ? (
                  <img src={productDetailsUpdate.product_img} />
                ) : (
                  " "
                )}
                <p>{productDetailsUpdate.display_name}</p>
              </div>
            }
            placement="bottom"
            onClose={onClose}
            open={open}
            footer={
              <>
                <Form
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onSubmit}
                >
                  <div className={styles.formBtnBox}>
                    <Row>
                      <Col span={24} className={styles.itemDrawerFooter}>
                        <div className={styles.totalItemBox}>
                          {disableBtn == false ? (
                            <Form.Item label="" name="totalItem">
                              <div className={styles.addItemCounter}>
                                <MinusOutlined
                                  className={styles.countIcon}
                                  onClick={() => {
                                    changeQuantity(
                                      productDetailsUpdate,
                                      "minus"
                                    );
                                  }}
                                />
                                <Input
                                  className={styles.quantityInput}
                                  type="number"
                                  value={productDetailsUpdate.quantity}
                                  onChange={(e) => {
                                    changeQuantity(
                                      productDetailsUpdate,
                                      "number",
                                      e.target.value
                                    );
                                  }}
                                />
                                <PlusOutlined
                                  className={styles.countIcon}
                                  onClick={() => {
                                    changeQuantity(productDetailsUpdate, "add");
                                  }}
                                />
                              </div>
                            </Form.Item>
                          ) : (
                            <Form.Item label="" name="totalItem">
                              <div className={styles.disabledItemCounter}>
                                <MinusOutlined
                                  className={styles.countIcon}
                                  onClick={() => {
                                    changeQuantity(
                                      productDetailsUpdate,
                                      "minus"
                                    );
                                  }}
                                />
                                <p>1</p>
                                <PlusOutlined
                                  className={styles.countIcon}
                                  onClick={() => {
                                    changeQuantity(productDetailsUpdate, "add");
                                  }}
                                />
                              </div>
                            </Form.Item>
                          )}
                        </div>
                        <div className={styles.addItemsCart}>
                          <Form.Item>
                            <Button htmlType="submit">
                              Add Items{" "}
                              {`${Number(
                                productDetailsUpdate.productInclusivePricecalculatedprice
                                  ? productDetailsUpdate.productInclusivePricecalculatedprice
                                  : productDetailsUpdate.calculatedprice
                              ).toFixed(2)}`}
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </>
            }
          >
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onSubmit}
              className={styles.drawerForm}
            >
              {(productDetailsUpdate.option_variant_group?.length > 0 ||
                productDetailsUpdate.option_addon_group?.length > 0 ||
                productDetailsUpdate.option_item_group?.length > 0) && (
                <div>
                  {productDetailsUpdate.isVarience &&
                    productDetailsUpdate.option_variant_group.map(
                      (variantGroup, index) => {
                        return (
                          <div className={styles.itemOptionContainer}>
                            {/* <div className={styles.itemOptionCheckbox}> */}
                            <Form.Item
                              label={
                                <div>
                                  {variantGroup.variant_group_name}
                                  <small className="text-muted">
                                    {" "}
                                    (Choose 1)
                                  </small>
                                </div>
                              }
                              name="varient_id"
                              rules={[
                                {
                                  required: true,
                                  message: "Select a option",
                                },
                              ]}
                              className={styles.itemOptionInputFields}
                            >
                              <Space direction="vertical">
                                {variantGroup.product_variants.map((item) => {
                                  if (
                                    item.disable == undefined ||
                                    item.disable == false
                                  ) {
                                    return (
                                      <Radio
                                        value={item._id}
                                        checked={item.isSelected ? true : false}
                                        onChange={() =>
                                          handleVarintGroup(item._id, index)
                                        }
                                        className={styles.radioItems}
                                      >
                                        <span>{item.variant_name}</span>
                                        {item.price !== 0 ? (
                                          <span>
                                            {currency}
                                            {Number(
                                              item.productInclusivePrice
                                                ? item.productInclusivePrice
                                                : item.price
                                            ).toFixed(2)}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </Radio>
                                    );
                                  }
                                })}
                              </Space>
                            </Form.Item>
                            {/* </div> */}
                          </div>
                        );
                      }
                    )}
                  {productDetailsUpdate.option_addon_group.map(
                    (addonGroup, index) => {
                      return (
                        <div className={styles.itemOptionContainer}>
                          {/* <div className={styles.itemOptionCheckbox}> */}
                          <Form.Item
                            name="addon_id"
                            className={styles.itemOptionInputFields}
                            label={
                              <div>
                                {addonGroup.addon_group_name}
                                <span className="text-muted">
                                  {addonGroup.minimum_selectable === 0
                                    ? null
                                    : ` (Addons) Choose atleast ${addonGroup.minimum_selectable}`}
                                  {addonGroup.maximum_selectable === 0
                                    ? ""
                                    : ` Max ${addonGroup.maximum_selectable}`}
                                </span>
                              </div>
                            }
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (
                                    addonGroup.minimum_selectable === 0 &&
                                    addonGroup.maximum_selectable === 0
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    if (
                                      addonGroup.minimum_selectable > 0 &&
                                      value.length <
                                        addonGroup.minimum_selectable
                                    ) {
                                      return Promise.reject(
                                        "Less than the min selectable limit."
                                      );
                                    } else if (
                                      value.length >
                                      addonGroup.maximum_selectable
                                    ) {
                                      return Promise.reject(
                                        "Exceeds the max selectable limit"
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  }
                                },
                              },
                            ]}
                          >
                            <Checkbox.Group className={styles.CheckBoxItems}>
                              <Space direction="vertical">
                                {addonGroup.product_addons.map(
                                  (item, addonIndex) => {
                                    if (
                                      item.disable == undefined ||
                                      item.disable == false
                                    ) {
                                      return (
                                        <Checkbox
                                          checked={item.isSelected}
                                          value={item._id}
                                          onChange={() => {
                                            handleAddonGroup(
                                              item,
                                              index,
                                              addonIndex
                                            );
                                          }}
                                          className={styles.CheckBoxContent}
                                        >
                                          {item.addon_name}
                                          {item.price !== "" ? (
                                            <span>
                                              {currency}
                                              {Number(
                                                item.productInclusivePrice
                                                  ? item.productInclusivePrice
                                                  : item.price
                                              ).toFixed(2)}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Checkbox>
                                      );
                                    }
                                  }
                                )}
                              </Space>
                            </Checkbox.Group>
                          </Form.Item>
                          {/* </div> */}
                        </div>
                      );
                    }
                  )}
                  {productDetailsUpdate.option_item_group != undefined &&
                    productDetailsUpdate.option_item_group.length >= 1 &&
                    productDetailsUpdate.option_status == "combo" &&
                    productDetailsUpdate.option_item_group.map(
                      (itemGroup, itemGroupIndex) => {
                        return (
                          <div className={styles.itemOptionContainer}>
                            {/* <div className={styles.itemOptionCheckbox}> */}
                            <Form.Item
                              className={styles.itemOptionInputFields}
                              label={
                                <div>
                                  {itemGroup.item_group_name}
                                  <small> (Choose 1)</small>
                                </div>
                              }
                              name="item_group_id_1"
                              rules={[
                                {
                                  required: true,
                                  message: "Select a items",
                                },
                              ]}
                            >
                              <Space direction="vertical">
                                {itemGroup.products.map((item, index) => {
                                  let FilterVarints =
                                    itemGroup.product_variants.filter(
                                      (data) => data.product_id._id === item._id
                                    );
                                  return (
                                    <>
                                      {FilterVarints.length > 0 ? (
                                        FilterVarints.map((data1, index1) => {
                                          return (
                                            <>
                                              <Radio
                                                value={`${data1.variant_id._id} varint`}
                                                onChange={(e) =>
                                                  handleItemGroup(
                                                    e,
                                                    itemGroupIndex
                                                  )
                                                }
                                                checked={
                                                  data1.isSelected
                                                    ? true
                                                    : false
                                                }
                                                className={styles.radioItems}
                                              >
                                                {`${data1.product_id.product_name} / ${data1.variant_id.variant_name}`}
                                              </Radio>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <>
                                          <Radio
                                            value={`${item._id} product`}
                                            onChange={(e) =>
                                              handleItemGroup(e, itemGroupIndex)
                                            }
                                            checked={
                                              item.isSelected ? true : false
                                            }
                                            className={styles.radioItems}
                                          >
                                            {item.product_name}
                                          </Radio>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </Space>
                            </Form.Item>
                            {/* </div> */}
                          </div>
                        );
                      }
                    )}
                </div>
              )}

              {/* <Form.Item name="orderTicketNotes" label="Add Instructions" className={styles.instructionInput}>
                <Input
                  size='large'
                  onBlur={(e) => instructionAdded(productDetailsUpdate, e.target.value)}
                />
              </Form.Item> */}
            </Form>
          </Drawer>
        </div>
      )}
    </>
  );
}

export default ProductDetail;
