import Cookies from 'js-cookie';
import actions from './actions';

const { GET_MENUITEM_LIST } = actions;

const initState = {
  menuitemsList: {},
};

const MenuListsReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_MENUITEM_LIST:
      return {
        ...state,
        menuitemsList: data,
      };

    default:
      return state;
  }
};
export default MenuListsReducer;
