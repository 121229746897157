import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import "../../css/custom.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Dropdown,
  Space,
  Skeleton,
  Modal,
} from "antd";
import { NavLink } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CaretRightOutlined,
  SearchOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  FilterOutlined,
  FilterFilled,
  CloseOutlined,
  FilterTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { menuItemsListData } from "../../redux/HomePage/actionCreater";
import dish1 from "../../Images/Home/dish1.png";
import menuIcon from "../../Images/Home/menuBook.svg";
import dish4 from "../../Images/Home/dish4.jpg";
import ProductDetail from "./ProductDetail";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";
const { Search } = Input;

function Home({ state }) {
  let localCartInfo = {};
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addFav, SetAddFav] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalItem, setTotalItem] = useState(1);
  const [totalCartItem, setTotalCartItem] = useState(0);
  const [selectCategoryItem, setSelectCategoryItem] = useState({});
  const [selectCategory, setSelectCategory] = useState("123");
  const [categoryAllItem, setCategoryAllItem] = useState([]);
  const [searchItemsList, setSearchItemsList] = useState([]);
  // console.log("🚀 ~ file: Home.js:58 ~ Home ~ searchItemsList:", searchItemsList)
  const [load, setLoad] = useState(true);
  const [productInfo, setProductInfo] = useState("");
  let [selectedProduct, setSelectedProduct] = useState(
    getItem("product_Details") != null ? getItem("product_Details") : []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchItems, setSearchItems] = useState("");
  const params = useParams();
  const [filterProduct, setFilterProduct] = useState(false);
  const [productType, setProductType] = useState("");
  const [productTypeList, setProductTypeList] = useState([]);

  const Data = useSelector((state) => state?.MenuListsReducer.menuitemsList);

  let productList = Data.productList ? Data.productList : [];
  let menuCategory = Data.categoryList ? Data.categoryList : [];

  const didMount = useRef(false);
  useEffect(() => {
    tableData();
  }, []);

  const tableData = async () => {
    if (params.table_id != "home") {
      let response = await dispatch(menuItemsListData(params.table_id));
      if (response && response.data) {
        if (
          response.data.hotel_on_off_details &&
          response.data.hotel_on_off_details.forcefully_hotel_off
        ) {
          navigate(`/app/hotel-closed`);
          setLoad(false);
          return true;
        } else if (
          response.data.hotel_on_off_details &&
          response.data.hotel_on_off_details.hotel_start_time &&
          response.data.hotel_on_off_details.hotel_end_time &&
          moment().isBetween(
            moment(
              response.data.hotel_on_off_details.hotel_start_time,
              "h:mm A"
            ),
            moment(response.data.hotel_on_off_details.hotel_end_time, "h:mm A")
          ) == false
        ) {
          navigate(`/app/hotel-closed`);
          setLoad(false);
          return true;
        }
        setLoad(false);
        let currency =
          response &&
          response.data &&
          response.data.shopDetails &&
          response.data.shopDetails.rs_symbol.slice(-2, -1);
        setItem("Currency", currency);
        setItem("table_id", params.table_id);
        setItem("table_name", response.data.tableDetails.table_name);
        setItem("register_id", response.data.tableDetails.register_id);
        setItem(
          "main_register_id",
          response.data.tableDetails.main_register_id
        );
        setItem("shop_name", response.data.shopDetails.shop_name);
        setItem(
          "hotel_room",
          response.data.tableDetails.hotel_room ? true : false
        );
        setItem("table_type", response.data.tableDetails.table_type);
      }
    } else {
      setLoad(false);
    }
  };

  let filterASearchProduct = (product) => {
    let filterList =
      product &&
      product?.filter((val) =>
        val.product_name.toLowerCase().includes(searchItems.toLowerCase())
      );
    setSearchItemsList(filterList);
    setSelectCategoryItem({});
  };

  useEffect(() => {
    filterASearchProduct(productList);
  }, [searchItems]);

  useEffect(() => {
    if (selectCategoryItem && selectCategoryItem._id) {
      filterCategoryWiseProductList(selectCategoryItem._id);
    }
  }, [selectCategoryItem]);

  const filterCategoryWiseProductList = (id) => {
    let filterList =
      productList &&
      productList?.filter((val) => val && val.product_category._id == id);
    setCategoryAllItem(filterList.length > 0 ? filterList : []);
  };

  useEffect(() => {
    if (productType !== "") {
      filterProductTypeList(productType);
    }
  }, [productType]);

  const filterProductTypeList = (type) => {
    let filterList =
      productList &&
      productList?.filter((value) =>
        type == "veg"
          ? !Object.keys(value).includes("product_type")
          : Object.keys(value).includes("product_type") == true &&
            value.product_type == type
      );

    setProductTypeList(filterList.length > 0 ? filterList : []);
    setSelectCategoryItem({});
  };
  const onSearch = (e) => {
    setSearchItems(e.target.value);
  };

  const removeSearch = () => {
    setSearchItems("");
  };

  const showModal = () => {
    setIsModalOpen(true);
    const body = document.querySelector("body");
    // Disable scroll
    body.style.overflow = "hidden";
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    const body = document.querySelector("body");
    // Enable scroll
    body.style.overflow = "auto";
  };

  // const itemCounterFunction = (val, info) => {
  //   if (val == "increment") {
  //     setTotalItem(totalItem + 1);
  //     setBillMoney(info.price * (totalItem + 1));
  //     setProductId(info._id);
  //   }
  //   if (val == "decrement") {
  //     totalItem > 1 ? setTotalItem(totalItem - 1) : setTotalItem(totalItem);
  //     totalItem > 1
  //       ? setBillMoney(info.price * (totalItem - 1))
  //       : setBillMoney(0);
  //     setProductId(info._id);
  //   }
  // };

  // const onSubmit = async (value) => {
  //   setTotalCartItem(totalItem);
  //   totalCartItems(value);
  //   setOpen(false);
  // };

  if (load) {
    return (
      <div className={styles.SkeletonContainer}>
        <Skeleton title width={150} active={true} />
        <br />
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <Space>
          <Skeleton.Button active={true} size="large" />
          <Skeleton.Input active={true} />
        </Space>
      </div>
    );
  }

  const items = [
    {
      key: "1",
      label: (
        <div
          className={styles.optionFilter}
          onClick={() => {
            setProductType("veg");
            setFilterProduct(true);
          }}
        >
          <div className={styles.vegFood}>
            <span></span>
          </div>
          <p>Veg</p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className={styles.optionFilter}
          onClick={() => {
            setProductType("non-veg");
            setFilterProduct(true);
          }}
        >
          <div className={styles.nonVegFood}>
            <span></span>
          </div>
          <p>Non-Veg</p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className={styles.optionFilter}
          onClick={() => {
            setProductType("other");
            setFilterProduct(true);
          }}
        >
          <div className={styles.eggFood}>
            <span></span>
          </div>
          <p>Egg-Items</p>
        </div>
      ),
    },

    {
      key: "4",
      label: (
        <div
          className={styles.resetBtn}
          onClick={() => {
            setProductType("");
            setFilterProduct();
            setProductTypeList([]);
          }}
        >
          <p>Reset</p>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col span={24}>
          <header className={styles.headerContainer}>
            <nav>
              {/* <h1>{Data.shopDetails.shop_name}</h1> */}
              <h1>{Data && Data.register_name}</h1>
              <p>{Data.tableDetails.table_name}</p>
              <div className={styles.filterAndSearchBox}>
                <div className={styles.searchContainer}>
                  <Search
                    size="large"
                    placeholder="search dish name"
                    onChange={(e) => onSearch(e)}
                    prefix={<SearchOutlined className={styles.searchIcon} />}
                    enterButton={false}
                    allowClear={{
                      clearIcon: <CloseOutlined onClick={removeSearch} />,
                    }}
                  />
                </div>

                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: ["4"],
                  }}
                >
                  {filterProduct == true ? (
                    <FilterTwoTone
                      className={styles.filterIcon}
                      twoToneColor="#d50c0c"
                    />
                  ) : (
                    <FilterFilled className={styles.filterIcon} />
                  )}
                </Dropdown>
              </div>
            </nav>
          </header>
        </Col>
      </Row>

      <Row className={styles.cardContainerRow}>
        <Col span={24}>
          {Object.keys(selectCategoryItem).length == 0 && searchItems == "" ? (
            <>
              <Divider>
                {productType !== ""
                  ? productType == "other"
                    ? "Egg Items"
                    : productType == "non-veg"
                    ? "Non-Veg Items"
                    : " Veg Items"
                  : "All Menu Items"}
              </Divider>
              <ProductDetail
                productListOfdata={
                  // productTypeList && productTypeList.length !== 0
                  productType !== "" ? productTypeList : productList
                }
                productCategory={selectCategory}
                setSelectedProduct={setSelectedProduct}
                selectedProduct={selectedProduct}
              />
            </>
          ) : (
            <>
              <Divider>{selectCategoryItem.category_name}</Divider>
              <ProductDetail
                productListOfdata={
                  searchItems == "" ? categoryAllItem : searchItemsList
                }
                setSelectedProduct={setSelectedProduct}
                selectedProduct={selectedProduct}
                productCategory={selectCategory}
              />
            </>
          )}
        </Col>
      </Row>

      <div className={styles.pageFooter}>
        <div className={styles.viewMenu}>
          <div className={styles.menuContainer} onClick={showModal}>
            <p>Menu</p>
          </div>
          {/* <Modal
            title="Menu"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className={styles.menuListModal}
          >
            <div>
              <ul className={styles.categoryList}>
                {menuCategory &&
                  menuCategory.map((category, i) => {
                    return (
                      <li
                        key={category._id}
                        onClick={() => {
                          setIsModalOpen(false);
                          if (category && category._id == "123") {
                            setSelectCategoryItem({});
                          } else {
                            setSelectCategoryItem(category);
                          }
                          setSelectCategory(category._id);
                        }}
                        className={
                          selectCategory == category._id ? "activeCategory" : "list"
                        }
                      >
                        {category.category_name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Modal> */}

          <Drawer
            title="Menu"
            placement="bottom"
            onClose={handleCancel}
            open={isModalOpen}
            rootClassName="menuDrawer"
          >
            <ul className={styles.categoryList}>
              {menuCategory &&
                menuCategory.map((category, i) => {
                  return (
                    <li
                      key={category._id}
                      onClick={() => {
                        setIsModalOpen(false);
                        const body = document.querySelector("body");
                        // Enable scroll
                        body.style.overflow = "auto";
                        if (category && category._id == "123") {
                          setSelectCategoryItem({});
                        } else {
                          setSelectCategoryItem(category);
                        }
                        setSelectCategory(category._id);
                      }}
                      className={
                        selectCategory == category._id
                          ? "activeCategory"
                          : "list"
                      }
                    >
                      {category.category_name}
                    </li>
                  );
                })}
            </ul>
          </Drawer>
        </div>
        {selectedProduct?.length ? (
          <div className={styles.viewCartDetailFooter}>
            <div className={styles.cartItems}>
              <ShoppingCartOutlined className={styles.cartIcon} />
              <p>{selectedProduct.length} Items Added</p>
            </div>
            <div className={styles.cartDetailBtn}>
              <NavLink to={{ pathname: `/app/${params.table_id}/viewCart` }}>
                <button>
                  Next <CaretRightOutlined />
                </button>
              </NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Home;
