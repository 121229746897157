import React, { useEffect } from "react";
import { Row, Col } from "antd";
import styles from "./staticPage.module.css";
import thankYou from "../../Images/thank-you.svg";
import takeAwayFood from "../../Images/takeaway-food-icon.svg";
import order from "../../Images/order.png";
import Success from "../../Images/success.png";
import orderPacket from "../../Images/bag.png";

function OrderCompletePage() {

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
    }, [])

    return (
        <div className={styles.orderCompleteBox}>
            <div className={styles.orderCompleteContent}>
                <div className={styles.textWrapper}>
                    <p className={styles.orderCompleteText}>
                        Your Order is Created Successfully{" "}
                    </p>
                </div>
                <div className={styles.thanksForOrder}>
                    <img src={takeAwayFood} alt="image..." />
                </div>
                <p className={styles.thankYouText}>Thanks For Order</p>
            </div>
        </div>
    );
}

export default OrderCompletePage;
