import React from 'react';
import noFoodProducts from '../../Images/Home/no_product_found.png';
import styles from "./notFound.module.css";


function NoFoodItem() {
    return (
        <div className={styles.noFoodItemFound}>
            <img src={noFoodProducts} alt='no Item Found...' />
        </div>
    )
}

export default NoFoodItem