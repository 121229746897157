import { combineReducers } from "redux";
import authReducer from "./authentication/reducers";
import MenuListsReducer from "./HomePage/reducers";

const rootReducers = combineReducers({
  auth: authReducer,
  MenuListsReducer,
});

export default rootReducers;
