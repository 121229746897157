import { Row, Col } from "antd";
import styles from "./staticPage.module.css";
import shopClose from "../../Images/closed-sign.png";
import Closed from "../../Images/closed.png";
import weClosed from "../../Images/closedShop.png";

function HotelClosedPage() {
  return (
    <>
      <div className={styles.orderCompleteBox}>
        <div className={styles.orderCompleteContent}>
          <div className={styles.textWrapper}>
            <p className={styles.headingText}>Sorry We Are Closed Today </p>
          </div>
          <div className={styles.thanksForOrder}>
            <img src={shopClose} alt="image..." />
          </div>
          <p className={styles.timeText}>See You Tomorrow </p>
          {/* </br><strong>9:00 AM</strong></p> */}
        </div>
      </div>
    </>
  );
}

export default HotelClosedPage;
