import secureLocalStorage from "react-secure-storage";

const getItem = (key) => {
  const data =
    typeof window !== "undefined" ? secureLocalStorage.getItem(key) : "";

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const setItem = (key, value) => {
  const stringify = typeof value !== "string" ? JSON.stringify(value) : value;
  return secureLocalStorage.setItem(key, stringify);
};

const removeItem = (key) => {
  secureLocalStorage.removeItem(key);
};

// const getCartInfoFromLocalKey = (key, registerDetails) => {
//   let local_cart_data = localStorage.getItem(localStorageCartKeyName);
//   local_cart_data = JSON.parse(local_cart_data);
//   if (local_cart_data) {
//     let cartData = local_cart_data.filter(function (itm) {
//       return itm.cartKey == key && itm.register_id === registerDetails?._id;
//     })[0];
//     if (cartData != null && Object.keys(cartData).length > 0) {
//     }

//     // localStorage.setItem("active_cart", key);
//     return cartData;
//   }
// };

export { getItem, setItem, removeItem, };
