import actions from "./actions";
import { message } from "antd";
// import { object } from 'prop-types';
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getMenuItemsData } = actions;

export const menuItemsListData = (tableId) => {
  return async (dispatch) => {
    const getData = await DataService.get(`${API.tableMenuData}/${tableId}`);
    if (!getData.data.error) {
      return dispatch(getMenuItemsData(getData?.data?.data));
    } else {
      message.error(getData.data.message);
      return false;
    }
  };
};
export const createOrder = (orderData) => {
  // console.log("🚀 ~ file: actionCreater.js:21 ~ createOrder ~ orderData:", orderData)
  return async (dispatch) => {
    const getData = await DataService.post(`${API.createOrder}`, orderData);
    if (!getData.data.error) {
      return getData.data;
    } else {
      message.error(getData.data.message);
      return false;
    }
  };
};
