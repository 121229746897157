import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Card,
  Radio,
  Modal,
} from "antd";
import {
  RightOutlined,
  MoreOutlined,
  HeartOutlined,
  StarFilled,
  HeartFilled,
  BankOutlined,
  GiftOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  PlusCircleOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { createOrder } from "../../redux/HomePage/actionCreater";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getItem, setItem } from "../../utility/localStorageControl";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useForm } from "antd/es/form/Form";

const { TextArea } = Input;

function ViewCart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [subTotal, setSubTotal] = useState(0);
  let [totalcalculatedPrice, setTotalCalculatedPrice] = useState(0);
  let [totalcalculatedTax, setTotalCalculatedTax] = useState(0);
  let [selectedProduct, setSelectedProduct] = useState(
    getItem("product_Details") != null ? getItem("product_Details") : []
  );

  let [tableType, setTableType] = useState(getItem("table_type"));

  let [taxesArray, setTaxesArray] = useState([]);
  const formRef = React.useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [number, setNumber] = useState("");
  const [instruction, setInstruction] = useState("");
  const [radioBtn, setRadioBtn] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function taxesCalculated(product) {
    product.taxGroup.taxes?.length > 0 &&
      product.taxGroup.taxes.map(
        (j) =>
          (j.totalTaxPrice = (j.tax_percentage * product.calculatedprice) / 100)
      );
    return (product.productTaxes * product.calculatedprice) / 100;
  }

  function getProductPrice(product) {
    let price = 0;
    if (product.isVarience) {
      price = Number(product.key_price);
    } else {
      price = Number(product.price);
    }
    return price;
  }

  function changeQuantity(product, getProductAddedIndex, check) {
    if (product && product.quantity == 1 && check == "minus") {
      selectedProduct.splice(getProductAddedIndex, 1);
      setSelectedProduct([...selectedProduct]);
      setItem("product_Details", [...selectedProduct]);
      return true;
    }
    selectedProduct[getProductAddedIndex]["quantity"] =
      check == "add"
        ? selectedProduct[getProductAddedIndex]["quantity"] + 1
        : selectedProduct[getProductAddedIndex]["quantity"] - 1;
    selectedProduct[getProductAddedIndex]["newqty"] =
      check == "add"
        ? selectedProduct[getProductAddedIndex]["newqty"] + 1
        : selectedProduct[getProductAddedIndex]["newqty"] - 1;
    selectedProduct[getProductAddedIndex].calculatedprice =
      selectedProduct[getProductAddedIndex].quantity * getProductPrice(product);
    if (
      selectedProduct[getProductAddedIndex].productInclusivePrice != undefined
    ) {
      selectedProduct[
        getProductAddedIndex
      ].productInclusivePricecalculatedprice =
        selectedProduct[getProductAddedIndex].quantity *
        (selectedProduct[getProductAddedIndex].productInclusivePriceKeyPrice
          ? selectedProduct[getProductAddedIndex].productInclusivePriceKeyPrice
          : selectedProduct[getProductAddedIndex].productInclusivePrice);
    }
    let pro = [...selectedProduct];
    setSelectedProduct(pro);
    setItem("product_Details", pro);
  }

  useEffect(() => {
    let total = 0;
    let totalTaxes = 0;
    let subTotalPrice = 0;
    let Taxesdata = [];
    selectedProduct.map((product) => {
      subTotalPrice += product.calculatedprice;

      if (product.productTaxes > 0) {
        totalTaxes += taxesCalculated(product);
      }
      product.taxGroup &&
        product.taxGroup.taxes.map((data) => {
          let totalTaxPrice = isNaN(data.totalTaxPrice)
            ? 0
            : data.totalTaxPrice;
          Taxesdata.push({
            name: data.tax_name,
            value: totalTaxPrice,
          });
        });
      total += product.calculatedprice;
    });
    var holder = {};
    // console.log("dsdsdsscvdvdvdsvdsv", Taxesdata);
    Taxesdata.forEach(function (d) {
      if (holder.hasOwnProperty(d.name)) {
        holder[d.name] = holder[d.name] + d.value;
      } else {
        holder[d.name] = d.value;
      }
    });
    var FinalTaxesArray = [];
    for (var prop in holder) {
      if (holder[prop] > 0) {
        FinalTaxesArray.push({ name: prop, value: holder[prop] });
      }
    }
    // console.log("nvfnbjkgnbnjgdfbdfgb", FinalTaxesArray);
    setTaxesArray(FinalTaxesArray);
    total = total + totalTaxes;
    total < 0
      ? setTotalCalculatedPrice(0)
      : setTotalCalculatedPrice(Number(total).toFixed(2));
    totalTaxes < 0
      ? setTotalCalculatedTax(0)
      : setTotalCalculatedTax(totalTaxes);
    setSubTotal(subTotalPrice);
  }, [selectedProduct]);

  const onChange = (e) => {
    setRadioBtn(e.target.value);
    setTableType(e.target.value);
  };
  const [loading, setLoading] = useState(false);
  const handleOrder = async (val) => {
    if (selectedProduct.length && loading == false) {
      setLoading(true);
      let orderData = {
        main_register_id: getItem("main_register_id"),
        register_id: getItem("register_id"),
        added_items: selectedProduct,
        table_id: getItem("table_id"),
        table_name: getItem("table_name"),
        table_type: tableType,
        hotel_room: getItem("hotel_room"),
        customer: {
          mobile: number,
          name: userName,
          shipping_address: shippingAddress,
          instruction: getItem("instruction"),
        },
        details: {
          added_items: selectedProduct,
          priceSummery: {
            total: totalcalculatedPrice,
            totalTaxes: totalcalculatedTax,
            sub_total: subTotal,
          },
        },
      };

      let response = await dispatch(createOrder(orderData));
      if (!response.er1ror) {
        setLoading(false);
        setItem("product_Details", []);
        setItem("instruction", "");
        navigate(`/app/order-complete`);
      }
    } else {
      message.error("Please select one product");
    }
    // const body = document.querySelector("body");
    // body.style.overflow = "auto";
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };
  const handleCancel = () => {
    setIsDrawerOpen(false);
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const submitInstruction = () => {
    instruction !== "" && instruction !== undefined && instruction !== null
      ? setItem("instruction", instruction)
      : setItem("instruction", "");
    setIsModalOpen(false);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.cartDetailContainer}>
      <Row>
        <Col span={24}>
          <div className={styles.cartSummeryContainer}>
            <Divider>ITEMS ADDED</Divider>
            <Card className={styles.cartItemsCard}>
              {selectedProduct.map((val, index) => {
                return (
                  <div className={styles.CartItemCounterBox}>
                    <div className={styles.cartItemDetail}>
                      <p>{val.display_name}</p>
                      <p>
                        {getItem("Currency")}
                        {Number(
                          val.productInclusivePrice
                            ? val.productInclusivePriceKeyPrice
                              ? val.productInclusivePriceKeyPrice
                              : val.productInclusivePrice
                            : val.key_price
                              ? val.key_price
                              : val.price
                        ).toFixed(2)}
                      </p>
                    </div>
                    <div className={styles.totalItemPrice}>
                      <div className={styles.cartItemCounter}>
                        <MinusOutlined
                          className={styles.countIcon}
                          onClick={() => changeQuantity(val, index, "minus")}
                        />
                        <p className={styles.totalItemCount}>{val.quantity}</p>
                        <PlusOutlined
                          className={styles.countIcon}
                          onClick={() => changeQuantity(val, index, "add")}
                        />
                      </div>

                      <p>
                        {getItem("Currency")}
                        {Number(
                          val.productInclusivePricecalculatedprice
                            ? val.productInclusivePricecalculatedprice
                            : val.calculatedprice
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Card>

            <Card
              className={styles.cartItemsCard}
              onClick={() => navigate(`/app/${getItem("table_id")}`)}
            >
              <div className={styles.CartItemCounterBox}>
                <div className={styles.addMoreItems}>
                  <PlusCircleOutlined />
                  <p>Add More Items</p>
                </div>
                <RightOutlined />
              </div>
            </Card>

            {/* <Card
              className={styles.cartItemsCard}
              onClick={() => navigate(`/app/hotel-closed`)}
            >
              <div className={styles.CartItemCounterBox}>
                <div className={styles.addMoreItems}>
                  <MoreOutlined />
                  <p>Hotel time</p>
                </div>
                <RightOutlined />
              </div>
            </Card> */}


            <Card className={styles.cartItemsCard} onClick={showModal}>
              <div className={styles.CartItemCounterBox}>
                <div className={styles.addMoreItems}>
                  <HighlightOutlined />
                  <p>Add Instruction</p>
                </div>
                <RightOutlined />
              </div>
            </Card>

            <Divider>BILL SUMMERY</Divider>
            <Card className={styles.cartItemsCard}>
              <div className={styles.subTotal}>
                <p>SubTotal</p>
                <p>
                  {getItem("Currency")}
                  {Number(subTotal).toFixed(2)}
                </p>
              </div>

              {taxesArray.length
                ? taxesArray.map((j) => {
                  return (
                    <div className={styles.CartItemCounterBox}>
                      <div className={styles.addMoreItems}>
                        <p>{j.name}</p>
                      </div>
                      <div className={styles.addMoreItems}>
                        <p>
                          {getItem("Currency")}
                          {Number(j.value).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  );
                })
                : ""}

              <Divider />
              <div className={styles.CartBillBox}>
                <p>Grand Total</p>
                <p>
                  {getItem("Currency")}
                  {totalcalculatedPrice}
                </p>
              </div>
            </Card>
          </div>
          <div className={styles.pageFooter}>
            <div className={styles.personalInfoBtn}>
              <Button onClick={showDrawer}>Confirm Order</Button>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        title="Add Instructions"
        centered={true}
        open={isModalOpen}
        onOk={submitInstruction}
        onCancel={handleCancelModal}
      >
        <Form layout="vertical" autoComplete="off">
          <Form.Item name="instruction" label="Add Instructions">
            <Input
              size="large"
              placeholder="add extra instruction for product.."
              onBlur={(e) => setInstruction(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Drawer
        title="Add Customer Detail"
        placement="bottom"
        onClose={handleCancel}
        open={isDrawerOpen}
        className="cartDrawer"
        footer={
          <>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleOrder}
            >
              <div className={styles.formBtnBox}>
                <Row>
                  <Col span={24}>
                    <div className={styles.addInfoCartBtns}>
                      <Form.Item>
                        <Button
                          className={styles.cancelOrderBtn}
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                      <Form.Item className={styles.addItemsCart}>
                        <Button htmlType="submit">
                          {loading ? "Loading..." : "Confirm"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </>
        }
      >
        <Row>
          <Col span={24}>
            <Form
              form={form}
              ref={formRef}
              className={styles.customerInfoForm}
              onFinish={handleOrder}
            >
              <Form.Item
                label="Customer Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Name!",
                  },
                ]}
              >
                <Input
                  onBlur={(e) => setUserName(e.target.value)}
                  size="large"
                />
              </Form.Item>

              <Form.Item
                name="mobile"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter contact number*!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "field only accept numbers (No space allowed!!*)",
                  },
                  {
                    max: 10,
                    message: "Maximum 10 Numbers are allowed!",
                  },
                ]}
              >
                <Input
                  type="number"
                  onBlur={(e) => setNumber(e.target.value)}
                  size="large"
                  onKeyPress={(event) => {
                    if (event.key.match("[0-9]+")) {
                      return true;
                    } else {
                      return event.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              {getItem("table_type") == "takeaway-delivery" ? (
                <Form.Item
                  name="table_type"
                  label="Order Type"
                  rules={[
                    {
                      required: true,
                      message: "Please Choose any One Option!",
                    },
                  ]}
                >
                  <Radio.Group onChange={onChange} value={radioBtn}>
                    <Radio value="takeaway">Takeaway</Radio>
                    <Radio value="delivery">Delivery</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : (
                ""
              )}

              {radioBtn == "delivery" ? (
                <Form.Item
                  name="shipping_address"
                  label="Shipping Address"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Address !!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    onBlur={(e) => setShippingAddress(e.target.value)}
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </Form>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
}

export default ViewCart;
