const actions = {
  GET_MENUITEM_LIST: 'GET_MENUITEM_LIST',


  getMenuItemsData: data => {
    return {
      type: actions.GET_MENUITEM_LIST,
      data,
    };
  },
};

export default actions;